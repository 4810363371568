import {defineStore} from 'pinia';

import {getRandomAlphanumericString, validateEmail, validatePhone} from "@/shared/helpers/strings";
import {useMenusStore} from "@/new-menu/stores/menus.store";
import {useLanguageStore, useNotificationStore, useScriptStore} from "@/shared/stores";
import API from "@/shared/helpers/API";
import {formatDateTime, getLocalDateFromServer} from "@/shared/helpers/dates";
import {useEvsStore} from "@/new-menu/stores/evs.store";
import {useActionsStore} from "@/new-menu/stores/actions.store";

export const useCartStore = defineStore('cart', {
	state: () => ({
		cart: [],
		ordersQueue: null,
		openCart: false,
		notes: null,
		tableNumber: null,
		buffering: true,
		notificationType: null,
		customerName: null,
		customerEmail: null,
		customerPhone: null,
		customerAddress: null,
		customerAddressDetails: null,
		courierRate: null,
		notInPlaceOrderDate: new Date(),
		notInPlaceOrderTime: null,
		sectionTableNumberHighlight: false,
		sectionCoverNumHighlight: false,
		sectionNameHighlight: false,
		sectionBufferHighlight: false,
		sectionNotInPlaceDataHighlight: false,
		sectionPhoneHighlight: false,
		sectionTimePickerHighlight: false,
		sectionCourierHighlight: false,
		sectionCouponsHighlight: false,
		deliveryAsap: true,
		showEmailPopup: false,
		payTableSheetActive: false,
		emailPopupCallback: null,
		coverChargeNum: 1,
		coverInfo: null,
		terminalId: terminalId,
		terminalIdToUse: null,
		appliedCoupons: [],
	}),
	getters: {
		getItemQuantity: (state) => { return (key) => {
			return state.cart.filter(cartItem => cartItem.menu_item_id === key.menu_item_id).reduce((total, cartItem) => {
				return total + cartItem.quantity;
			}, 0);
		}},
		getCategoryQuantity: (state) => { return (key) => {
			let quantity = 0;
			state.cart.forEach(cartItem => {
				if(cartItem.category_id === key.menu_category_id) {
					quantity += cartItem.quantity;
				}
			});
			return quantity;
		}},
		getMenuQuantity: (state) => { return (key) => {
			let quantity = 0;
			let categoriesIds = key.categories.map(category => category.menu_category_id);
			state.cart.forEach(cartItem => {
				if(cartItem.category_id && categoriesIds.includes(cartItem.category_id)) {
					quantity += cartItem.quantity;
				}
			});
			return quantity;
		}},
		totalItems: (state) => {
			return state.cart.reduce((total, cartItem) => {
				return total + cartItem.quantity* (cartItem.price + cartItem.products.reduce((total, product) => {
					return total + product.quantity*product.product_price;
				}, 0));
			}, 0);
		},
		itemsQuantity: (state) => {
			return state.cart.reduce((total, cartItem) => {
				return total + cartItem.quantity;
			}, 0);
		},
		recommendedItemsIds: (state) => {
			let menusStore = useMenusStore();

			let possibleRecommendation = {};
			let cartItemsIds = state.cart.map(cartItem => cartItem.menu_item_id);
			state.cart.forEach(cartItem => {
				let item = menusStore.getItemById(cartItem.menu_item_id);
				if(item && item.associations && item.associations.length > 0 && item.orderability === "standard" && menusStore.isItemAvailable(item)) {
					item.associations.forEach(association => {
						if(!cartItemsIds.includes(Number(association.id))) {
							if(!possibleRecommendation.hasOwnProperty(association.id)) {
								possibleRecommendation[association.id] = 0;
							}
							possibleRecommendation[association.id] += association.qs;
						}
					});
				}
			});

			return Object.entries(possibleRecommendation).sort((a, b) => {
				return b[1] - a[1];
			}).map(entry => {
				return Number(entry[0]);
			});
		},
		recommendedItemsIdsSelectedMenu: (state) => {
			let menusStore = useMenusStore();
			if(!menusStore.business.options.cross_selling && !menusStore.business.options.cross_selling_popup) {
				return [];
			}
			let itemsCount = menusStore.selectedMenuItemsQuantity;
			let recommendedMax;
			if(itemsCount <= 15) {
				recommendedMax = 1;
			} else if(itemsCount <= 35) {
				recommendedMax = 2;
			} else if(itemsCount <= 60) {
				recommendedMax = 3;
			} else {
				recommendedMax = 4;
			}

			let filtered = state.recommendedItemsIds.filter(recommendedId => {
				let item = menusStore.getItemById(recommendedId);
				if(item) {
					let cat = menusStore.allCategories.find(category => category.menu_category_id === item.category_id);
					if(cat) {
						return cat.menu_id === menusStore.selectedMenuId && menusStore.isItemAvailable(item);
					} else {
						return false;
					}
				} else {
					return false;
				}
			});

			return filtered.slice(0, recommendedMax);
		},
		minOrder: (state) => {
			let menusStore = useMenusStore();
			if(menusStore.orderMode === "qr") {
				if(menusStore.business.options.min_order !== null) {
					return menusStore.business.options.min_order;
				}
			} else if(menusStore.orderMode === "delivery") {
				if(menusStore.business.options.delivery_min_order !== null) {
					return menusStore.business.options.delivery_min_order;
				}
			} else if(menusStore.orderMode === "takeaway") {
				if(menusStore.business.options.takeaway_min_order !== null) {
					return menusStore.business.options.takeaway_min_order;
				}
			}
			return 50;
		},
		additionalCosts: (state) => {
			let result = 0;

			let menusStore = useMenusStore();
			if(menusStore.orderMode === 'delivery') {
				if(menusStore.business.options.delivery_costs) {
					result += menusStore.business.options.delivery_costs;
				}
				if(state.courierPrice) {
					result += state.courierPrice;
				}
			} else if(menusStore.orderMode === 'takeaway') {
				if(menusStore.business.options.takeaway_costs) {
					result += menusStore.business.options.takeaway_costs;
				}
			} else if(menusStore.orderMode === "qr") {
				if(menusStore.weconstudioInfo && menusStore.weconstudioInfo.bollo_threshold && menusStore.weconstudioInfo.bollo_value) {
					let bolloThreshold = parseInt(menusStore.weconstudioInfo.bollo_threshold);
					if(state.totalItems >= bolloThreshold) {
						result += parseInt(menusStore.weconstudioInfo.bollo_value);
					}
				}

				if((menusStore?.room?.cover_charge_everytime || !state.coverInfo) && menusStore?.room?.cover_charge) {
					if(menusStore.room.cover_charge_type) {
						result += menusStore.room.cover_charge;
					} else if(!menusStore.room.cover_charge_type && state.coverChargeNum > 0) {
						result += state.coverChargeNum * menusStore.room.cover_charge;
					}
				}
			}
			if(state.appliedCoupons.length > 0) {
				result -= state.couponDiscount;
			}

			return result;
		},
		couponDiscount: (state) => {
			let fixedDiscount = 0;
			let percentageDiscount = 0;
			let discountValue = 0;
			state.appliedCoupons.forEach(coupon => {
				if(coupon.linked_products) {
					let total = state.totalOfItemsOrCategories(coupon.products.map(p => Number(p.item_id)), coupon.categories.map(c => Number(c.category_id)));
					if(coupon.fixed_value) {
						discountValue += Math.min(coupon.fixed_value, total);
					} else if(coupon.percentage_value) {
						discountValue += total * coupon.percentage_value / 100;
					}
				} else {
					if(coupon.fixed_value) {
						fixedDiscount += coupon.fixed_value;
					} else if(coupon.percentage_value) {
						percentageDiscount += coupon.percentage_value;
					}
				}
			});
			let discount = Math.round(fixedDiscount + discountValue + ((state.totalItems - discountValue - fixedDiscount) * percentageDiscount / 100));
			if(discount > state.totalItems) {
				discount = state.totalItems;
			}
			return discount > 0 ? discount : 0;
		},
		grandTotal: (state) => {
			return state.totalItems + state.additionalCosts;
		},
		courierPrice: (state) => {
			let menusStore = useMenusStore();
			if(menusStore.orderMode !== 'delivery' || !state.courierRate) {
				return null;
			}
			if(menusStore.business.options.delivery_courier_free_shipping !== null && state.totalItems >= menusStore.business.options.delivery_courier_free_shipping) {
				return null;
			} else {
				return Math.round(parseFloat(state.courierRate.rate)*122);
			}
		},
	},
	actions: {
		getSuggestedItems() {
			if(this.cart.length === 0) {
				return [];
			}
			let menusStore = useMenusStore();

			//Cross-selling
			let crossSellingIds = this.recommendedItemsIds;
			let itemsCross = [];
			for(let i = 0; i < crossSellingIds.length; i++) {
				let item = menusStore.getItemById(crossSellingIds[i]);
				if(item && item.orderability === "standard" && menusStore.isItemAvailable(item)) {
					itemsCross.push(item);
				}
			}

			//Most popular
			let itemsPop = [];
			let mostPopularItems = menusStore.menusItemsSold.map(item => menusStore.getItemById(Number(item.id))).filter(item => item && item.orderability === "standard" && menusStore.isItemAvailable(item));
			for(let i = 0; i < mostPopularItems.length; i++) {
				if(!itemsPop.find(item => item.menu_item_id === mostPopularItems[i].menu_item_id)) {
					itemsPop.push(mostPopularItems[i]);
				}
			}
			itemsPop = itemsPop.filter((item) => this.cart.findIndex(i => i.menu_item_id === item.menu_item_id) === -1);

			//Business picks
			let businessPicks = [];
			menusStore.allProducts.forEach(item => {
				if(item.cross_selling_priority && item.orderability === "standard" && menusStore.isItemAvailable(item)) {
					businessPicks.push(item);
				}
			});
			businessPicks = businessPicks.sort((a, b) => b.cross_selling_priority - a.cross_selling_priority);
			businessPicks = businessPicks.filter((item) => this.cart.findIndex(i => i.menu_item_id === item.menu_item_id) === -1);

			let items = businessPicks.concat(itemsCross.slice(0, 3)).concat(itemsPop.slice(0, 3)).concat(itemsCross.slice(3, 6)).concat(itemsPop.slice(3, 6));
			//remove duplicates
			items = items.filter((item, index) => items.findIndex(i => i.menu_item_id === item.menu_item_id) === index);
			return items.slice(0, 10);
		},
		totalOfItemsOrCategories(itemIds, categoriesIds) {
			return this.cart.reduce((total, cartItem) => {
				if(itemIds.includes(cartItem.menu_item_id) || (cartItem.category_id && categoriesIds.includes(cartItem.category_id))) {
					return total + cartItem.quantity * (cartItem.price + cartItem.products.reduce((total, product) => {
						return total + product.quantity*product.product_price;
					}, 0));
				} else {
					return total;
				}
			}, 0);
		},
		refreshOrdersQueue(callback) {

			let menusStore = useMenusStore();
			let shouldFetchOrdersQueue = menusStore.orderMode === 'qr' && menusStore.business.options.queue_active_menu;
			let shouldFetchTableCalls = !!menusStore.enabledRoomTableCalls && menusStore.roomTableId;

			if(shouldFetchOrdersQueue || shouldFetchTableCalls || menusStore.payBillAvailable) {
				let tkn = typeof tknTmp !== 'undefined' ? tknTmp : null;
				API.init().getOrdersQueue(menusStore.business.business_id, null, shouldFetchOrdersQueue, menusStore.payBillAvailable, shouldFetchTableCalls, tkn, (response) => {
					if(response && response.result === "OK") {
						if(shouldFetchOrdersQueue) {
							this.ordersQueue = parseInt(response?.orders || 0);
						}
						if(shouldFetchTableCalls) {
							menusStore.fetchedRoomTableCallId = response?.["active_room_table_call_id"] ? Number(response["active_room_table_call_id"]) : null;
						}
						if(menusStore.payBillAvailable && response.table_orders && response.table_orders_items && response.table_orders_items_products && response.table_orders_payments) {
							const actionsStore = useActionsStore();
							actionsStore.normalizeOrders(response.table_orders, response.table_orders_items, response.table_orders_items_products, response.table_orders_payments);
						}
					}
					callback && callback(true);
				}, () => {
					callback && callback(false);
				});
			}

		},
		startOrdersQueuePolling() {
			this.refreshOrdersQueue(() => {
				setTimeout(this.startOrdersQueuePolling, 25000);
			});
		},
		getItemsWithSkuQuantity(sku) {
			let menusStore = useMenusStore();
			return this.cart.reduce((total, cartItem) => {
				let item = menusStore.getItemById(cartItem.menu_item_id);
				let partialTotal = total;
				if(item && item.warehouse && item.sku && item.sku === sku) {
					partialTotal += cartItem.quantity;
				}
				if(cartItem.products) {
					cartItem.products.forEach(product => {
						if(product.warehouse_sku && product.warehouse_sku === sku) {
							partialTotal += product.quantity;
						}
					});
				}
				return partialTotal;
			}, 0);
		},
		itemAvailabilityChecks(item, category, quantity = 1) {
			let menusStore = useMenusStore();
			if(item.availableQuantity !== null && quantity + this.getItemQuantity(item) > item.availableQuantity) {
				let notificationsStore = useNotificationStore();
				notificationsStore.showNotification("item_not_available_quantity", [item.availableQuantity, item.nameTranslation]);
				return false;
			} else if(item.warehouse && item.sku && menusStore.skusQuantities.hasOwnProperty(item.sku) && menusStore.skusQuantities[item.sku] < quantity + this.getItemsWithSkuQuantity(item.sku)) {
				let notificationsStore = useNotificationStore();
				notificationsStore.showNotification("product_unavailable_other_cart", [item.nameTranslation, menusStore.skusQuantities[item.sku]]);
				return false;
			} else if(category !== null && category.activeQuantity !== null && quantity + this.getCategoryQuantity(category) > category.activeQuantity) {
				let notificationsStore = useNotificationStore();
				let languageStore = useLanguageStore();
				let activeQuantityString = category.activeQuantity === 1 ? languageStore.getString("product_single") : languageStore.getString("products_x", [category.activeQuantity]);
				notificationsStore.showNotification("category_not_available_quantity", [activeQuantityString.toLowerCase(), category.nameTranslation]);
				return false;
			} else if(item.available === false || (item.orderability !== "standard" && !item.deepen && !item.composite && !item.ingredientsFull)) {
				let notificationsStore = useNotificationStore();
				notificationsStore.showNotification("item_not_available", [item.nameTranslation]);
				return false;
			}

			if(menusStore.weconstudioInfo && menusStore.weconstudioInfo.max_products) {
				let maxProducts = parseInt(menusStore.weconstudioInfo.max_products);
				if(maxProducts > 0 && this.itemsQuantity >= maxProducts) {
					let notificationsStore = useNotificationStore();
					notificationsStore.showNotification("max_items", [maxProducts]);
					return false;
				}
			}
			return true;
		},
		//performs the default action for the item:
		//simple item -> add to cart
		//composite/deepen item -> show bottom sheet
		//item with long description -> show bottom sheet
		triggerItem(item, category, router, route, quantity = 1) {
			let menusStore = useMenusStore();
			if(menusStore.orderMode === "none") {
				if(item.deepen || item.composite || item.ingredientsFull) {
					menusStore.selectedItemId = item.menu_item_id;
					if(item.deepen && item.slug) {
						if(route.params.menuId) {
							router.push("/"+route.params.menuId+"/"+item.slug+"/");
						} else {
							if(menusStore.selectedMenu) {
								let menuSlug = menusStore.selectedMenu.slug ? menusStore.selectedMenu.slug : menusStore.selectedMenu.menu_id;
								router.push("/"+menuSlug+"/"+item.slug+"/");
							} else {
								console.error("No menu selected")
							}
						}
					}
					useEvsStore().enqueueEvent('view', "deepen", item.menu_item_id);
				}
			} else {
				if(this.itemAvailabilityChecks(item, category, quantity)) {
					if(item.deepen || item.composite || item.ingredientsFull) {
						let menusStore = useMenusStore();
						menusStore.selectedItemId = item.menu_item_id;
						if(item.deepen && item.slug) {
							if(route.params.menuId) {
								router.push("/"+route.params.menuId+"/"+item.slug+"/");
							} else {
								if(menusStore.selectedMenu) {
									let menuSlug = menusStore.selectedMenu.slug ? menusStore.selectedMenu.slug : menusStore.selectedMenu.menu_id;
									router.push("/"+menuSlug+"/"+item.slug+"/");
								} else {
									console.error("No menu selected")
								}
							}
						}
						useEvsStore().enqueueEvent('view', "deepen", item.menu_item_id);
					} else {
						this.addItemQuantity(item, category, quantity);
					}
				}
			}
		},
		//use to add a non composite item
		addItemQuantity(item, category, quantity = 1) {
			let menusStore = useMenusStore();
			if(menusStore.orderMode === "none") {
				return;
			}
			if(this.itemAvailabilityChecks(item, category, quantity)) {
				let found = this.cart.find(cartItem => cartItem.menu_item_id === item.menu_item_id);
				if(found) {
					found.quantity+=quantity;
				} else {
					this.cart.push({
						temp_id: getRandomAlphanumericString(10),
						menu_item_id: item.menu_item_id,
						category_id: item.category_id,
						quantity: quantity,
						price: item.price,
						name: item.name,
						products: [],
						notes: null,
					});
				}
				this.saveCart();
				localStorage.setItem("dismissOrderHere", "true");
				menusStore.hideOrderHere = true;
				useEvsStore().enqueueEvent('click', 'add-item-to-cart', item.menu_item_id);
			}
		},
		//use to add a composite item
		addItemComposite(item, category, products, notes, quantity) {
			let menusStore = useMenusStore();
			if(menusStore.orderMode === "none") {
				return;
			}
			if(this.itemAvailabilityChecks(item, category, quantity) && item.orderability === "standard") {
				if(!notes) {
					let found = this.cart.filter(cartItem => cartItem.menu_item_id === item.menu_item_id);
					if(found.length > 0) {
						// check if the same products are already selected in another item already in the cart that doesn't have notes
						for(let i = 0; i < found.length; i++) {
							let cartItem = found[i];
							if(!cartItem.notes && productsAreSame(cartItem.products, products)) {
								cartItem.quantity += quantity;
								this.saveCart();
								return;
							}
						}
					}
				}
				this.cart.push({
					temp_id: getRandomAlphanumericString(10),
					menu_item_id: item.menu_item_id,
					category_id: item.category_id,
					quantity: quantity,
					price: item.price,
					name: item.name,
					products: products,
					notes: notes,
				});
				this.saveCart();
				localStorage.setItem("dismissOrderHere", "true");
				menusStore.hideOrderHere = true;
				useEvsStore().enqueueEvent('click', 'add-item-to-cart', item.menu_item_id);
			}
		},
		//use to increase the quantity of an item already in the cart
		addSpecificCartItem(tempId, item, category, quantity = 1) {
			let menusStore = useMenusStore();
			if(menusStore.orderMode === "none") {
				return;
			}
			if(this.itemAvailabilityChecks(item, category, quantity) && item.orderability === "standard") {
				let found = this.cart.find(cartItem => cartItem.temp_id === tempId);
				if(found) {
					found.quantity+=quantity;
				}
				this.saveCart();
				useEvsStore().enqueueEvent('click', 'add-item-to-cart', item.menu_item_id);
			}
		},
		removeItemQuantity(item) {
			let found = this.cart.find(cartItem => cartItem.menu_item_id === item.menu_item_id);
			if(found) {
				found.quantity--;
				if(found.quantity <= 0) {
					this.cart.splice(this.cart.indexOf(found), 1);
				}
				this.saveCart();
				useEvsStore().enqueueEvent('click', 'remove-item-from-cart', item.menu_item_id);
			}
		},
		removeSpecificCartItem(tempId) {
			let found = this.cart.find(cartItem => cartItem.temp_id === tempId);
			if(found) {
				found.quantity--;
				if(found.quantity <= 0) {
					this.cart.splice(this.cart.indexOf(found), 1);
					if(this.cart.length === 0) {
						this.openCart = false;
					}
				}
				this.saveCart();
				useEvsStore().enqueueEvent('click', 'remove-item-from-cart', found.menu_item_id);
			}
		},
		removeProductsWithAllergens(allergensIds) {
			let menusStore = useMenusStore();
			let newCart = [];
			this.cart.forEach(cartItem => {
				let menuItem = menusStore.getItemById(cartItem.menu_item_id);
				if(menuItem && !menuItem.allergens.some(allergen => allergensIds.includes(allergen.allergen_id))) {
					newCart.push(cartItem);
				}
			});
			this.cart = newCart;
			this.saveCart();
		},
		saveCart(updateDate = true) {
			localStorage.setItem('cart', JSON.stringify(this.cart));
			if(updateDate) {
				localStorage.setItem("cartDate", Date.now()+"");
			}
		},
		loadCart() {
			let menusStore = useMenusStore();
			let cart = localStorage.getItem('cart');
			let cartDate = localStorage.getItem("cartDate");
			if(cart && cartDate && Date.now() - parseInt(cartDate) < 1000*60*60*4) {

				try {
					cart = JSON.parse(cart);

					let menusStore = useMenusStore();

					// Check if cart items are still available
					let newCart = [];
					cart.forEach(cartItem => {
						if(!cartItem.hasOwnProperty("category_id") || !cartItem.hasOwnProperty("menu_item_id") || !cartItem.hasOwnProperty("temp_id") ||
							!cartItem.hasOwnProperty("quantity") || !cartItem.hasOwnProperty("price") || !cartItem.hasOwnProperty("name") || !cartItem.hasOwnProperty("products") || !Array.isArray(cartItem.products)) {
							console.warn("Cart item corrupted, removing from cart", cartItem);
							this.clearCart();
							return;
						}

						let item = menusStore.getItemById(cartItem.menu_item_id);
						if(item && this.canItemBeInCart(item, cartItem)) {
							let availableQuantity = item.availableQuantity !== null ? (item.availableQuantity - cartItem.quantity >= 0) : true;
							if(availableQuantity) {
								newCart.push(cartItem);
							} else {
								if(item.availableQuantity > 0) {
									console.warn("Cart item quantity not available anymore, reducing quantity to available quantity");
									newCart.push({
										...cartItem,
										quantity: item.availableQuantity,
									});
								} else {
									console.warn("Cart item not available anymore, removing from cart");
								}
							}
						}
					});

					//check if categories limits are respected
					menusStore.allCategories.forEach(category => {
						if(category.active === false) {
							//remove all cart items from this category
							newCart = newCart.filter(cartItem => cartItem.category_id !== category.menu_category_id);
						} else if(category.active === null && category.activeQuantity !== null) {

							let categoryQuantity = newCart.filter(cartItem => cartItem.category_id === category.menu_category_id).reduce((total, cartItem) => {
								return total + cartItem.quantity;
							}, 0);
							if(categoryQuantity > category.activeQuantity) {
								console.warn("Cart items quantity for category not available anymore, removing all cart items from this category");
								newCart = newCart.filter(cartItem => cartItem.category_id !== category.menu_category_id);
							}

						}
					});

					this.cart = newCart;
					this.saveCart(false);
				} catch(e) {
					console.error("Error loading cart", e);
					this.clearCart();
				}

			}

			if(menusStore.orderMode === "qr") {

				let coverNumber = sessionStorage.getItem('coverChargeNum');
				if(menusStore?.room?.cover_charge !== null && !menusStore?.room?.cover_charge_type && coverNumber !== null && parseInt(coverNumber) > 0 && parseInt(coverNumber) <= 30) {
					this.coverChargeNum = parseInt(coverNumber);
				}

				let tableNumber = sessionStorage.getItem('tableNumber');
				if(menusStore.qrType === "none") {
					this.tableNumber = tableNumber ? parseInt(tableNumber) : null;
				}

				if(!menusStore?.room?.cover_charge_everytime && roomId) {
					let coverInfo = localStorage.getItem("coverInfo-"+roomId);
					coverInfo = coverInfo ? JSON.parse(coverInfo) : null;
					if(coverInfo && coverInfo.order_date){
						let lastDateOrder = getLocalDateFromServer(coverInfo.order_date).getTime();
						if(Date.now() - lastDateOrder < 2000*60*60) {
							this.coverInfo = coverInfo;
						} else {
							this.coverInfo = null;
							localStorage.removeItem("coverInfo-"+roomId);
						}
					}
				}
			}

		},
		canItemBeInCart(item, cartItem) {
			if(item && item.price === cartItem.price && item.orderability === "standard") {
				let menusStore = useMenusStore();
				let itemCategory = item.category_id ? menusStore.getCategoryById(item.category_id) : null;
				if(itemCategory && menusStore.isCategoryAvailable(itemCategory)) {
					if(item.available || (item.available === null && item.availableTime)) {
						return true;
					} else {
						console.warn("Cart item not available anymore");
					}
				}
			}
		},
		clearCart() {
			this.cart = [];
			localStorage.removeItem('cart');
		},
		getCouriers(callback) {
			if(!this.customerAddress) {
				callback(false, null);
				return;
			}

			let menusStore = useMenusStore();

			let phone = this.customerPhone && menusStore.currentCountry ? menusStore.currentCountry.prefix_number + this.customerPhone : '';
			let name = this.customerName ? this.customerName : '';
			let email = this.customerEmail ? this.customerEmail : '';

			API.init().getRates(menusStore.business.business_id, null, null, JSON.stringify(this.customerAddress.data), phone, name, email, this.getItemsFormatCourier(), r => {
				if (r && r.result === "OK" && r.rates && r.rates.length > 0) {
					callback(true, r.rates);
				} else {
					callback(false, r && r.details ? r.details : null);
				}
			});

		},
		checkout(paymentMethod, callback) {

			this.cart = this.cart.filter(cartItem => cartItem.quantity > 0);
			this.cart.forEach(cartItem => {
				cartItem.products = cartItem.products.filter(product => product.quantity > 0);
			});
			if(this.cart.length === 0) {
				let notificationStore = useNotificationStore();
				notificationStore.showNotification("empty_cart");
				callback(null);
				return;
			}
			if(this.grandTotal < this.minOrder) {
				let notificationStore = useNotificationStore();
				let languageStore = useLanguageStore();
				notificationStore.showNotification("min_order", [languageStore.readableEurValue(this.minOrder/100)]);
				callback(null);
				return;
			}
			let menusStore = useMenusStore();
			if(["delivery", "takeaway"].includes(menusStore.orderMode)) {
				this.checkoutNotInPlace(paymentMethod, callback);
			} else {
				this.checkoutInPlace(paymentMethod, callback);
			}
		},
		requireEmail(callback) {
			const menusStore = useMenusStore();
			let noEmail = !this.customerEmail || !validateEmail(this.customerEmail);
			let qrModeConditions = menusStore.business.options.enabled_box_email_cash && menusStore.orderMode === "qr";
			let notInPlaceConditions = ["delivery", "takeaway"].includes(menusStore.orderMode);
			if(noEmail && (qrModeConditions || notInPlaceConditions)) {
				this.showEmailPopup = true;
				this.emailPopupCallback = (res) => {
					if(res) {
						callback();
					}
				}
			} else {
				callback();
			}
		},
		checkoutInPlace(paymentMethod, callback) {

			this.fieldsCheck((success, tableNumber, seatNumber, customerName, phoneNumber, customerEmail) => {

				let menusStore = useMenusStore();
				menusStore.saveData();

				if(success) {

					this.execCaptcha((success, token) => {

						if(success && token) {

							useEvsStore().enqueueEvent('checkout-in-place', paymentMethod, JSON.stringify({
								tot: this.grandTotal,
							}));

							let tkn = typeof tknTmp !== 'undefined' ? tknTmp : null;
							let hasCoverCharge = menusStore?.room?.cover_charge_type !== null && (menusStore?.room?.cover_charge_everytime || !this.coverInfo);
							let coverChargeNum = hasCoverCharge ? this.coverChargeNum : 0;
							let coverChargePrice = hasCoverCharge ? menusStore?.room?.cover_charge : null;
							let coverInfo = menusStore?.room?.cover_charge_type !== null && !menusStore?.room?.cover_charge_everytime && this.coverInfo ? JSON.stringify(this.coverInfo) : null;
							let buffering = menusStore.business.options.buffer_time !== null;
							if(menusStore.business.options.buffer_customers) {
								buffering = this.buffering;
							}

							API.init().checkoutInPlace(paymentMethod,
								menusStore.business.business_id,
								token,
								this.getItemsFormatAPI(),
								this.notes,
								customerName,
								menusStore.qrVersion,
								tableNumber,
								seatNumber,
								coverChargeNum,
								coverChargePrice,
								coverInfo,
								menusStore.qrType,
								customerEmail,
								phoneNumber,
								this.notificationType,
								tkn,
								this.terminalIdToUse,
								this.appliedCoupons.map(coupon => coupon.code),
								buffering,
								(response) => {

									if(response && response.result === "OK") {
										callback(response);
									} else {
										if(response.details) {
											this.handleCheckoutError(response);
										}
										callback(null);
									}

							});
						} else {
							let notificationStore = useNotificationStore();
							notificationStore.showNotification("invalid_captcha");
							callback(null);
						}

					});

				} else {
					callback(null);
				}

			})


		},
		checkoutNotInPlace(paymentMethod, callback) {

			this.fieldsCheckNotInPlace((success, customerName, customerEmail, phoneNumber, addressData, scheduledDate, courierRateId) => {

				let menusStore = useMenusStore();
				menusStore.saveData();

				if(success) {

					this.execCaptcha((success, token) => {

						if(success && token) {

							useEvsStore().enqueueEvent('checkout-'+menusStore.orderMode, paymentMethod, JSON.stringify({
								tot: this.grandTotal,
							}));

							API.init().checkoutNotInPlace(menusStore.business.business_id,
								token,
								this.getItemsFormatAPI(),
								this.notes,
								paymentMethod,
								customerName,
								customerEmail,
								phoneNumber,
								addressData ? addressData.address : null,
								addressData && this.customerAddressDetails ? this.customerAddressDetails : null,
								addressData ? addressData.lat : null,
								addressData ? addressData.lng : null,
								addressData ? JSON.stringify(addressData.data) : null,
								scheduledDate,
								courierRateId,
								menusStore.orderMode,
								this.appliedCoupons.map(coupon => coupon.code),
								(response) => {

									if (response && response.result === 'OK') {
										callback(response);
									} else {
										if(response.details) {
											this.handleCheckoutError(response);
										}
										callback(null);
									}
								});

						} else {
							let notificationStore = useNotificationStore();
							notificationStore.showNotification("invalid_captcha");
							callback(null);
						}

					});
				} else {
					callback(null);
				}

			})

		},
		execCaptcha(callback) {

			if(typeof grecaptcha !== "undefined") {
				grecaptcha.ready(() => {
					let captchaPK = '6Lc_oK8ZAAAAAHHQSZ2DkU3Ja-jCDML2hqpuIBuC';
					grecaptcha.execute(captchaPK, {action: 'checkout'}).then((token) => {
						callback(true, token);
					}, (error) => {
						console.error(error);
						setTimeout(() => {
							grecaptcha.execute(captchaPK, {action: 'checkout'}).then((token) => {
								callback(true, token);
							}, (error) => {
								console.error(error);
								callback(false, null);
							});
						}, 50);
					});
				});
			} else {
				console.error("captcha undefined")
				callback(false, null);
			}

		},
		fieldsCheck(callback) {

			let menusStore = useMenusStore();

			let customerName = null;
			let tableName = null;
			let seatNumber = null;
			let phoneNumber = null;
			let customerEmail = null;

			if(menusStore.orderMode === "qr") {
				if(menusStore.qrType) {
					if(['name', 'area'].includes(menusStore.qrType)) {
						if(this.customerName && this.customerName.replaceAll(" ", "").length > 0) {
							customerName = this.customerName;
						} else {
							let notificationStore = useNotificationStore();
							notificationStore.showNotification("missing_customer_name");
							callback(false);
							this.alertSectionName();
							return;
						}
					}

					if(['none'].includes(menusStore.qrType)) {

						if(this.tableNumber) {
							tableName = this.tableNumber;
						} else {
							let notificationStore = useNotificationStore();
							notificationStore.showNotification("missing_table_number");
							callback(false);
							this.alertSectionTableNumber();
							return;
						}

					} else if(['table', 'seat', 'area'].includes(menusStore.qrType)) {
						tableName = typeof tableNameFromToken !== 'undefined' && !!tableNameFromToken ? tableNameFromToken : null;
					}

					if(['seat'].includes(menusStore.qrType)) {
						seatNumber = typeof seatNumberFromToken !== 'undefined' && !!seatNumberFromToken ? seatNumberFromToken : null;
					}
				}

				if(menusStore.room?.enabled_sms) {
					if(this.notificationType !== null && this.customerPhone && menusStore.currentCountry) {
						if(validatePhone(this.customerPhone)) {
							phoneNumber = menusStore.currentCountry.prefix_number + this.customerPhone;
						} else {
							let notificationStore = useNotificationStore();
							notificationStore.showNotification("invalid_phone");
							callback(false);
							this.alertSectionPhone();
							return;
						}
					} else {
						let notificationStore = useNotificationStore();
						notificationStore.showNotification("missing_phone_number");
						callback(false);
						this.alertSectionPhone();
						return;
					}
				}

				if(this.customerEmail && validateEmail(this.customerEmail)) {
					customerEmail = this.customerEmail;
				}

			}


			callback(true, tableName, seatNumber, customerName, phoneNumber, customerEmail);

		},
		fieldsCheckNotInPlace(callback) {

			let menusStore = useMenusStore();

			let customerName = null;
			let customerEmail = null;
			let phoneNumber = null;
			let addressData = null;
			let orderDate = null;
			let courierRateId = null;

			let deliveryDateCheck = menusStore.orderMode === "delivery" && menusStore.isDeliveryFutureAvailable && !this.deliveryAsap;
			let takeawayDateCheck = menusStore.orderMode === "takeaway";
			if(deliveryDateCheck || takeawayDateCheck) {

				if(this.notInPlaceOrderDate) {
					if(this.notInPlaceOrderTime && this.notInPlaceOrderTime.split(":").length === 2) {
						orderDate = new Date(this.notInPlaceOrderDate);
						let time = this.notInPlaceOrderTime.split(":");
						orderDate.setHours(time[0]);
						orderDate.setMinutes(time[1]);
						orderDate.setSeconds(0);
						orderDate = formatDateTime(orderDate, true);
					} else {
						let notificationStore = useNotificationStore();
						notificationStore.showNotification("missing_order_time");
						callback(false);
						this.alertSectionTimePicker();
						return;
					}
				} else {
					let notificationStore = useNotificationStore();
					notificationStore.showNotification("missing_order_date");
					callback(false);
					this.alertSectionTimePicker();
					return;
				}

			}


			if(["delivery", "takeaway"].includes(menusStore.orderMode)) {

				if(this.customerName && this.customerName.replaceAll(" ", "").length > 0) {
					customerName = this.customerName;
				} else {
					let notificationStore = useNotificationStore();
					notificationStore.showNotification("missing_customer_name");
					callback(false);
					this.alertSectionNotInPlaceData();
					return;
				}

				if(this.customerEmail && validateEmail(this.customerEmail)) {
					customerEmail = this.customerEmail;
				}

				if(this.customerPhone && menusStore.currentCountry) {
					if(validatePhone(this.customerPhone)) {
						phoneNumber = menusStore.currentCountry.prefix_number + this.customerPhone;
					} else {
						let notificationStore = useNotificationStore();
						notificationStore.showNotification("invalid_phone");
						callback(false);
						this.alertSectionNotInPlaceData();
						return;
					}
				} else {
					let notificationStore = useNotificationStore();
					notificationStore.showNotification("missing_phone_number");
					callback(false);
					this.alertSectionNotInPlaceData();
					return;
				}

			}

			if(menusStore.orderMode === "delivery") {
				if(this.customerAddress) {
					addressData = this.customerAddress;
				} else {
					let notificationStore = useNotificationStore();
					notificationStore.showNotification("missing_address");
					callback(false);
					this.alertSectionNotInPlaceData();
					return;
				}

				if(menusStore.business.options.delivery_courier) {
					if(this.courierRate) {
						courierRateId = this.courierRate.rate_id;
					} else {
						let notificationStore = useNotificationStore();
						notificationStore.showNotification("missing_courier");
						callback(false);
						this.alertSectionCourier();
						return;
					}
				}
			}

			callback(true, customerName, customerEmail, phoneNumber, addressData, orderDate, courierRateId);
		},
		handleCheckoutError(response, payBill = false) {
			let error = response.details;
			let menusStore = useMenusStore();
			let notificationStore = useNotificationStore();
			if (["param","items","items_check","items_check_count","business_id","prices","takeaway_split_day","takeaway_split_time","stripe_account"].includes(error)) {
				notificationStore.showNotification("order_failed");
			} else if (error === "captcha") {
				notificationStore.showNotification("captcha_failed");
			} else if (["method", "not_subscribed_cash"].includes(error)) {
				notificationStore.showNotification("payment_method_unaccepted", [menusStore.business.name]);
			} else if (error === "amount") {
				let minOrder = business.options.min_order ? business.options.min_order : 50;
				let languageStore = useLanguageStore();
				notificationStore.showNotification("min_order", [languageStore.readableEurValue(minOrder/100)]);
			} else if (error === "min_order") {
				let languageStore = useLanguageStore();
				notificationStore.showNotification("min_order", [languageStore.readableEurValue(business.options.delivery_min_order/100)]);
			} else if (error === "min_order_takeaway") {
				let languageStore = useLanguageStore();
				notificationStore.showNotification("min_order", [languageStore.readableEurValue(business.options.takeaway_min_order/100)]);
			} else if (error === "no_orders_now") {
				notificationStore.showNotification("no_orders_now", [menusStore.business.name]);
			} else if (error === "invalid_amount_type") {
				notificationStore.showNotification("invalid_amount_type");
				this.refreshOrdersQueue();
			} else if (["location", "too_far"].includes(error)) {
				notificationStore.showNotification("far_away_explain", [menusStore.business.name]);
			} else if (["time", "token"].includes(error)) {
				notificationStore.showNotification(payBill ? "long_ago_pay_bill" : "long_ago");
			} else if (error === "courier_rate") {
				notificationStore.showNotification("courier_no_rate");
			} else if (error === "warehouse") {
				let menuItemId = response.menu_item_id ? Number(response.menu_item_id) : null;
				let threshold = response.threshold ? response.threshold : null;
				let menuItem = menuItemId ? menusStore.getItemById(menuItemId) : null;
				if(menuItem) {
					if(threshold > 0) {
						notificationStore.showNotification("product_unavailable_warehouse", [menuItem.nameTranslation, threshold]);
					} else {
						notificationStore.showNotification("product_unavailable", [menuItem.nameTranslation]);
					}
				} else {
					notificationStore.showNotification("products_unavailable");
				}
			} else if (error === "courier_payment") {
				notificationStore.showNotification("courier_payment_explain");
			} else if (["satispay_not_active", "satispay_invoice_fail", "btc_not_active", "btc_invoice_fail"].includes(error)) {
				notificationStore.showNotification("payment_method_issue");
			} else if (error === "delivery") {
				notificationStore.showNotification("delivery_not_accepted", [menusStore.business.name]);
			} else if (error === "takeaway") {
				notificationStore.showNotification("takeaway_not_accepted", [menusStore.business.name]);
			} else if (error === "stock") {
				notificationStore.showNotification("stock_invalid");
			} else if (["cover_charge_changed", "cover_calculation", "cover_tkn", "cover_charge_num"].includes(error)) {
				notificationStore.showNotification("cover_charge_invalid", [menusStore?.room?.cover_charge_nameTranslation]);
				localStorage.removeItem("coverInfo");
				this.coverInfo = null;
			} else if (error === "cover_charge_num") {
				notificationStore.showNotification("cover_charge_invalid", [menusStore?.room?.cover_charge_nameTranslation]);
			} else if (error === "takeaway_time_not_found") {
				notificationStore.showNotification("takeaway_time_invalid");
			} else if (error === "coupons") {
				notificationStore.showNotification("coupon_invalid");
			} else if (error === "fee_greater_than_total") {
				notificationStore.showNotification("fee_greater_than_total");
			} else {
				notificationStore.showNotification("order_failed_later");
			}
		},
		getItemsFormatAPI() {
			let itemsToSend = [];
			this.cart.forEach(item => {
				let products = item.products.map(product => {
					return {
						menu_item_list_product_id: product.menu_item_list_product_id,
						quantity: product.quantity,
						nested: false,
					}
				});
				let productsObj = products.reduce((obj, product) => {
					obj[product.menu_item_list_product_id] = product;
					delete product.menu_item_list_product_id;
					return obj;
				}, {});
				itemsToSend.push({id: item.menu_item_id, category_id: item.category_id, quantity: item.quantity, choices: products.length > 0 ? [productsObj] : null, notes: item.notes ? item.notes : null});
			});
			return JSON.stringify(itemsToSend);
		},
		getItemsFormatCourier() {
			let itemsToSend = [];
			this.cart.forEach(item => {
				itemsToSend.push({unit_price: item.price, quantity: item.quantity});
			});
			return JSON.stringify(itemsToSend);
		},
		scrollIntoView(element, repeatCount = 0) {
			if(element) {
				element.scrollIntoView({behavior: "smooth", block: window.visualViewport.height < 520 ? "start" : "center"});

				let resizeHandler;
				if (window.innerWidth < 920 && window.visualViewport.height < 920) {
					resizeHandler = () => {
						window.visualViewport.removeEventListener('resize', resizeHandler);
						setTimeout(() => {
							element.scrollIntoView({ behavior: "smooth", block: window.visualViewport.height < 520 ? "start" : "center" });
						}, 300);
					};
					window.visualViewport.addEventListener('resize', resizeHandler);

					setTimeout(() => {
						window.visualViewport.removeEventListener('resize', resizeHandler);
					}, 1500);
				}
			}
		},
		alertSectionTableNumber(highlight = true) {
			let section = document.getElementById("order-section-table-number");
			if(section) {
				this.scrollIntoView(section);
			}
			if(highlight) {
				this.sectionTableNumberHighlight = true;
				setTimeout(() => {
					this.sectionTableNumberHighlight = false;
				}, 1200);
			}
		},
		alertSectionName(highlight = true) {
			let section = document.getElementById("order-section-name");
			if(section) {
				this.scrollIntoView(section);
			}
			if(highlight) {
				this.sectionNameHighlight = true;
				setTimeout(() => {
					this.sectionNameHighlight = false;
				}, 1200);
			}
		},
		alertSectionPhone(highlight = true) {
			let section = document.getElementById("order-section-phone-number");
			if(section) {
				let sectionInput = section.querySelector("input");
				this.scrollIntoView(sectionInput ? sectionInput : section);
			}
			if(highlight) {
				this.sectionPhoneHighlight = true;
				setTimeout(() => {
					this.sectionPhoneHighlight = false;
				}, 1200);
			}
		},
		alertSectionNotInPlaceData(highlight = true) {
			let section = document.getElementById("order-section-not-in-place-data");
			if(section) {
				this.scrollIntoView(section);
			}
			if(highlight) {
				this.sectionNotInPlaceDataHighlight = true;
				setTimeout(() => {
					this.sectionNotInPlaceDataHighlight = false;
				}, 1200);
			}
		},
		alertSectionTimePicker(highlight = true) {
			let section = document.getElementById("order-section-time-picker");
			if(section) {
				this.scrollIntoView(section);
			}
			if(highlight) {
				this.sectionTimePickerHighlight = true;
				setTimeout(() => {
					this.sectionTimePickerHighlight = false;
				}, 1200);
			}
		},
		alertSectionCourier(highlight = true) {
			let section = document.getElementById("order-section-couriers");
			if(section) {
				this.scrollIntoView(section);
			}
			if(highlight) {
				this.sectionCourierHighlight = true;
				setTimeout(() => {
					this.sectionCourierHighlight = false;
				}, 1200);
			}
		},
		alertSectionCoupons(highlight = true) {
			let section = document.getElementById("order-section-coupons");
			if(section) {
				this.scrollIntoView(section);
			}
			if(highlight) {
				this.sectionCouponsHighlight = true;
				setTimeout(() => {
					this.sectionCouponsHighlight = false;
				}, 1200);
			}
		},
		scrollToSectionCover() {
			let section = document.getElementById("order-section-cover");
			if(section) {
				this.scrollIntoView(section);
			}
		},
		scrollToSectionNotes() {
			let section = document.getElementById("order-section-notes");
			if(section) {
				this.scrollIntoView(section);
			}
		},
		normalizeAppliedCoupons(coupons, couponsProducts, couponsRooms) {

			let couponsProductsObj = {};
			let couponsCategoriesObj = {};
			for(let i = 0; i < couponsProducts.length; i++) {
				if(couponsProducts[i].item_id) {
					if(!couponsProductsObj[couponsProducts[i].business_coupon_id]) {
						couponsProductsObj[couponsProducts[i].business_coupon_id] = [];
					}
					couponsProductsObj[couponsProducts[i].business_coupon_id].push(couponsProducts[i]);
				} else if(couponsProducts[i].category_id) {
					if(!couponsCategoriesObj[couponsProducts[i].business_coupon_id]) {
						couponsCategoriesObj[couponsProducts[i].business_coupon_id] = [];
					}
					couponsCategoriesObj[couponsProducts[i].business_coupon_id].push(couponsProducts[i]);
				}
			}

			let couponsRoomsObj = {};
			for(let i = 0; i < couponsRooms.length; i++) {
				if(!couponsRoomsObj[couponsRooms[i].business_coupon_id]) {
					couponsRoomsObj[couponsRooms[i].business_coupon_id] = [];
				}
				couponsRoomsObj[couponsRooms[i].business_coupon_id].push(couponsRooms[i]);
			}

			for(let i = 0; i < coupons.length; i++) {
				coupons[i].business_coupon_id = Number(coupons[i].business_coupon_id);
				coupons[i].delivery = coupons[i].delivery === "1";
				coupons[i].takeaway = coupons[i].takeaway === "1";
				coupons[i].qr_mode = coupons[i].qr_mode === "1";
				coupons[i].linked_products = coupons[i].linked_products === "1";
				coupons[i].linked_rooms = coupons[i].linked_rooms === "1";
				coupons[i].minimum_order_value = coupons[i].minimum_order_value ? Number(coupons[i].minimum_order_value) : null;
				coupons[i].products = couponsProductsObj[coupons[i].business_coupon_id] || [];
				coupons[i].categories = couponsCategoriesObj[coupons[i].business_coupon_id] || [];
				coupons[i].rooms = couponsRoomsObj[coupons[i].business_coupon_id] || [];
				coupons[i].percentage_value = coupons[i].percentage_value ? Number(coupons[i].percentage_value) : null;
				coupons[i].fixed_value = coupons[i].fixed_value ? Number(coupons[i].fixed_value) : null;
			}

			return coupons;

		},
	}
});

function productsAreSame(arr1, arr2) {
	if (arr1.length !== arr2.length) {
		return false;
	}

	arr1.sort((a, b) => a.menu_item_list_product_id - b.menu_item_list_product_id);
	arr2.sort((a, b) => a.menu_item_list_product_id - b.menu_item_list_product_id);

	// Step 3: Compare each object in both sorted arrays
	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i].menu_item_list_product_id !== arr2[i].menu_item_list_product_id || arr1[i].quantity !== arr2[i].quantity) {
			return false;
		}
	}

	return true;
}
